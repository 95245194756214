<template>
    <div class="page">
        <a-spin :spinning="isLoading" tip="加载中...">
            <div class="head">
                <img src="../../../static/images/firstHead.png" alt="">
            </div>
            <div class="cen">
                <img style="width: 100%;height: 384px;" src="../../../static/images/firstDraw.png" alt="">
                <div class="cen_box">
                    <slotMachine ref="LuckyDraw" width="235px" height="150px" :blocks="blocks" :slots="slots"
                        :prizes="prizes" :defaultConfig="defaultConfig" :defaultStyle="defaultStyle"
                        @end="endCallback" />
                </div>
                <div class="cen_btn" @click="darwBtn"></div>
            </div>
            <div class="info">
                <div class="info_title">
                    <span style="margin-right: 5px;"></span>
                    中奖名单
                    <span style="margin-left: 5px;"></span>
                </div>
                <div class="info_list">
                    <div v-if="clientData.message&&clientData.message.length!=0">
                        <!-- <div v-for="(item,index) of list" :key="index"> -->
                        <div class="list_box">
                            <!-- <span>11-22 11:11</span> -->
                            <span>{{ clientData.message.nickname }}</span>
                            <span>{{ clientData.message.prize_name }}</span>
                        </div>
                        <!-- </div> -->
                    </div>
                    <div class="recordBox" v-else>暂无中奖记录</div>
                </div>
            </div>
            <div class="record_title">
                <img src="../../../static/images/fifthRecond.png" alt="">
            </div>
            <div class="record_box">
                <div class="record_mar">
                    <div v-if="clientData.win_list&&clientData.win_list.length!=0">
                        <div v-for="(item,index) of clientData.win_list" :key="index">
                            <div class="record_list">
                                <div class="box_title">{{item.createdAt}}</div>
                                <div class="box_info">
                                    <div class="info_left">
                                        <img :src="item.prizeImage || item.receiveQr" alt="">
                                        <span>{{ item.prizeName }}</span>
                                    </div>
                                    <div v-if="item.receiveType == 3 ||item.receiveType == 4||item.receiveType == 6">
                                        <div class="info_right" v-if="item.receiveStatus == 0" @click="skip(item)">兑换
                                        </div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else>已兑换</div>
                                    </div>
                                    <div v-else>
                                        <div class="info_right" v-if="item.receiveStatus == 0"
                                            @click="receiveReward(item)">兑换</div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else @click="receiveReward(item)">已兑换</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-else description="暂无记录" />
                </div>
            </div>
            <div style="width: 100%;height: 40px;"></div>
            <div class="pop_up">
                <prize ref="prize" @childEvent="getClientData"/>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import { contactLotteryApi } from "@/api/lottery";
    import { publicMixin } from "@/mixin/index.js"
    export default {
        mixins: [publicMixin],
        data() {
            return {
                isLoading: false,
                list: [],
                blocks: [
                    { padding: '10px', background: '#EC1A15', borderRadius: '10px' },
                    { padding: '5px', background: '#FE9F43', borderRadius: '10px' },
                    { padding: '5px', background: '#FFFCEB', borderRadius: '10px' },
                ],
                // blocks: [{
                //   imgs: [{
                //     src:  require("../../../static/images/firstDraw.png"),
                //     width: '100%',
                //     height: '100%'
                //   }]
                // }],
                // slots: [
                //     { order: [1, 0, 1], speed: 20, direction: 1 },
                //     { order: [1, 0, 1], speed: 20, direction: -1 },
                //     { order: [0, 1, 0], speed: 20, direction: 1 },
                // ],
                slots: [],
                prizes: [],
                // prizes: [
                //     {
                //         borderRadius: '10px',
                //         fonts: [{ text: '0', top: '100%', fontColor: '#C93C1F', fontSize: '11px' }],
                //         imgs: [cellImg9]
                //     },
                //     {
                //         borderRadius: '10px',
                //         fonts: [{ text: '1', top: '100%', fontColor: '#C93C1F', fontSize: '11px' }],
                //         imgs: [cellImg9]
                //     },
                //     {
                //         borderRadius: '10px',
                //         fonts: [{ text: '2', top: '100%', fontColor: '#C93C1F', fontSize: '11px' }],
                //         imgs: [cellImg9]
                //     }
                // ],
                defaultStyle: {
                    borderRadius: Infinity,
                    //   background: '#bac5ee',
                    fontSize: '32px',
                    fontColor: '#333'
                },
                defaultConfig: {
                    rowSpacing: '20px',
                    colSpacing: '20px'
                },
                showCode: false,
                qrCode: "",
                url: window.location.href,
                // weChatUserNews: {}, //  用户微信信息
                clientData: {}, //  客户数据
                corp: {},
                // prizeSetNews: [{name:'11'},{name:'22'},{name:'33'},{name:'44'}],//  设置的奖品
                shareOption: {},
                openId: "",
                drawState: false,//是否抽奖
                // source: 'from_pc'
            }
        },
        // watch: {
        //     prizeData: { // 监控该变量，重新赋值并刷新图表
        //         handler(newVal, oldVal) {
        //             console.log("888",this.prizeData)
        //             this.prizeSetNews = newVal
        //             this.handlePrizes()
        //         },
        //         deep: true // 必须设置
        //     },
        // },

        created() {
            this.id = this.$route.query.id;
            this.prizeSetNews = this.prizeData
            this.handlePrizes()
            this.getClientData()
        },
        mounted() {
        },
        methods: {
            open(){
                this.debounce(this.openNext, 3000);
            },
            openNext() {
                if (this.drawState) return
                this.drawState = true
                //调取接口获取中奖数据
                contactLotteryApi({
                    id: this.id,
                    union_id: this.weChatUserNews.unionid,
                    nickname: this.weChatUserNews.nickname,
                }).then((res) => {
                    if (res.code == 200) {
                        this.$refs.LuckyDraw.play()
                        let prizeInfo = {};
                        this.prizeSetNews.map((item) => {
                            if (item.name === res.data.prize_name) {
                                prizeInfo = item;
                                let drawIndex = [item.id - 1, item.id - 1, item.id - 1]
                                //设置中奖停止参数
                                this.$refs.LuckyDraw.stop(drawIndex)
                            }
                        })
                        //弹窗提醒
                        setTimeout(() => {
                            this.drawState = false
                            let prizeData = {
                                openId: this.openId,
                                receiveType: res.data.receive_type,
                                prizeName: res.data.prize_name,
                                receiveCode: res.data.receive_code,
                                receiveQr: res.data.receive_qr,
                                description: res.data.description,
                                reduce_amount:res.data.reduce_amount,
                                record_id: res.data.record_id,
                                nickname: this.weChatUserNews.nickname,
                                headimgurl: this.weChatUserNews.headimgurl,
                                money:res.data.money,
                            };
                            // if (prizeInfo.type == 2) {
                                prizeData.receiveQr = prizeInfo.image;
                            // }
                            this.$refs.prize.show(prizeData);
                            this.getClientData();
                        }, 6000);
                    }

                });

            },
            darwBtn() {
                this.open();
            },
            endCallback(prize) {
                // console.log("抽奖结束", prize)
            },
            //处理奖品数据
            handlePrizes() {
                console.log("传递奖品数据", this.prizeSetNews)
                let newprizes = []
                let newslots = []
                this.prizeSetNews.forEach((ele, index) => {
                    //奖品图片
                    let prizesJson = {
                        borderRadius: '10px',
                        fonts: [{ text: ele.name, top: '80%', fontColor: '#C93C1F', fontSize: '11px' }],
                        imgs: [{
                            src: ele.image,
                            width: '80%',
                            height: '80%'
                        }]
                    }
                    //设置奖品
                    newprizes.push(prizesJson)
                    //设置奖品顺序
                    // let slotRandom1 = Math.floor(Math.random()*this.prizeSetNews.length)+1;
                    // let slotRandom2 = Math.floor(Math.random()*this.prizeSetNews.length)+1;
                    // let slotRandom3 = Math.floor(Math.random()*this.prizeSetNews.length)+1;
                    // let orderRandom =[this.prizeSetNews[slotRandom1-1].id,this.prizeSetNews[slotRandom2-1].id,this.prizeSetNews[slotRandom3-1].id]
                    let orderId = this.prizeSetNews.map((item) => { return item.id - 1 })
                    orderId = orderId.sort(() => 0.5 - Math.random())
                    let slotJson = { order: orderId, speed: 20, direction: index % 2 == 0 ? 1 : -1 }
                    if (newslots.length >= 3) return
                    newslots.push(slotJson)
                });
                // console.log("11", JSON.stringify(newslots))
                // console.log("22", JSON.stringify(newprizes))
                //slots最多可有3组,组越多，奖品列就越多
                //order  对应是奖品下标  从0开始
                this.slots = newslots
                this.prizes = newprizes
                // this.slots =[{"order":[1, 2, 4, 3],"speed":20,"direction":1},{"order":[1, 4, 3, 2],"speed":20,"direction":-1},{"order":[3, 2, 4, 1],"speed":20,"direction":1}]
                // let aa = [
                //     {"borderRadius":"10px","fonts":[{"text":1,"top":"80%","fontColor":"#C93C1F","fontSize":"11px"}],"imgs":[{"src":"http://test.api.bankmartech.com/static/image/lottery/16753340834898_63db91c377965.jpg","width":"80%","height":"80%"}]},
                //     {"borderRadius":"10px","fonts":[{"text":2,"top":"80%","fontColor":"#C93C1F","fontSize":"11px"}],"imgs":[{"src":"http://test.api.bankmartech.com/static/image/lottery/16753340834905_63db91c377be5.jpg","width":"80%","height":"80%"}]},
                //     {"borderRadius":"10px","fonts":[{"text":3,"top":"80%","fontColor":"#C93C1F","fontSize":"11px"}],"imgs":[{"src":"http://test.api.bankmartech.com/static/image/lottery/16753340834908_63db91c377d4e.jpg","width":"80%","height":"80%"}]},
                //     {"borderRadius":"10px","fonts":[{"text":4,"top":"80%","fontColor":"#C93C1F","fontSize":"11px"}],"imgs":[{"src":"http://test.api.bankmartech.com/static/image/lottery/16753340834912_63db91c377ecd.jpg","width":"80%","height":"80%"}]}]
                // // // order获取所有id 打乱赋值
                // this.prizes =aa

                //             this.slots= [
                //   { order: [1, 0, 1] },
                //   { order: [1, 0, 1] },
                //   { order: [0, 1, 0] },
                // ]
                // this.slots=[
                //     { order: [1, 2, 3,4], speed: 20, direction: 1 },
                //     { order: [1, 3, 2,4], speed: 20, direction: -1 },
                //     { order: [3, 2, 1,4], speed: 20, direction: 1 },
                // ]
                // this.slots=[
                //     { order: ["1等奖", "0等奖", "3等奖","2等奖"], speed: 20, direction: 1 },
                //     { order: ["1等奖", "0等奖", "2等奖","3等奖"], speed: 20, direction: -1 },
                //     { order: ["0等奖", "1等奖", "3等奖","2等奖"], speed: 20, direction: 1 },
                // ]
                // let cellImg1={
                //     src: require("../../../static/images/fourth1.png"),
                //             width: '80%',
                //             height: '80%'
                // }
                // let cellImg2={
                //     src: require("../../../static/images/fourth2.png"),
                //             width: '80%',
                //             height: '80%'
                // }
                // let cellImg3={
                //     src: require("../../../static/images/fourth3.png"),
                //             width: '80%',
                //             height: '80%'
                // }
                // let cellImg4={
                //     src: require("../../../static/images/fourth4.png"),
                //             width: '80%',
                //             height: '80%'
                // }
                // this.prizes=[
                //     {
                //         borderRadius: '10px',
                //         fonts: [{ text: '0', top: '100%', fontColor: '#C93C1F', fontSize: '11px' }],
                //         imgs: [cellImg1]
                //     },
                //     {
                //         borderRadius: '10px',//1
                //         fonts: [{ text: '1', top: '100%', fontColor: '#C93C1F', fontSize: '11px' }],
                //         imgs: [cellImg2]
                //     },
                //     {
                //         borderRadius: '10px',//2
                //         fonts: [{ text: '2', top: '100%', fontColor: '#C93C1F', fontSize: '11px' }],
                //         imgs: [cellImg3]
                //     }
                //     ,
                //     {
                //         borderRadius: '10px',
                //         fonts: [{ text: '3', top: '100%', fontColor: '#C93C1F', fontSize: '11px' }],
                //         imgs: [cellImg4]
                //     }
                // ]
            },

        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-empty{
        padding: 0px;
    }
    .page {
        width: 100%;
        height: 100%;
        background-image: url("../../../static/images/firstBack.png");
        background-size: cover;

        .head {
            padding: 60px 34px 24px 34px;

            img {
                width: 100%;
                height: 181px;
            }
        }

        .cen {
            width: 100%;
            height: 380px;
            display: flex;
            justify-content: center;
            /* background-image: url("../../../static/images/firstDraw.png");
            background-size: cover; */
            position: relative;

            .cen_box {
                position: absolute;
                top: 56px;
            }

            .cen_btn {
                width: 82px;
                height: 71px;
                background-image: url("../../../static/images/firstbtn.png");
                background-size: cover;
                position: absolute;
                bottom: 75px;
                /* left: 41%; */
                left: 50%;
                transform: translateX(-42%);
            }
        }

        .info {
            width: 80%;
            border-radius: 5px;
            background: #FEBE8D;
            padding: 8px 25px;
            border-top: #C90605 6px solid;
            margin: 0 auto;
            margin-top: 20px;

            .info_title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                font-weight: 500;
                color: #C90605;

                span {
                    width: 18px;
                    height: 2px;
                    background-color: #C90605;
                    /* border-top: 1px solid #C90605; */
                }
            }

            .info_list {
                margin-top: 2px;
                color: #C90605;

                .list_box {
                    display: flex;
                    justify-content: space-around;
                }
            }

            .recordBox {
                text-align: center;
                color: #C90605;
                font-size: 12px;
            }
        }

        .record_title {
            display: flex;
            justify-content: center;
            margin-top: 33px;

            img {
                width: 225px;
                height: 27px;
            }
        }

        .record_box {
            background: #FEBE8D;
            border-radius: 12px;
            margin: 15px;
            padding: 8px;
            margin-bottom: 30px;

            .record_mar {
                padding: 15px 22px;
                background: #FFFFFF;
                border-radius: 12px;
                height: 233px;
                overflow-y: scroll;

                .record_list {

                    .box_title {
                        font-size: 12px;
                        font-weight: 400;
                        color: #DC1912;
                    }

                    .box_info {
                        margin-top: 5px;
                        padding: 10px 12px;
                        background: #FFE5D9;
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .info_left {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            img {
                                display: flex;
                                flex-direction: row;
                                width: 45px;
                                height: 45px;
                            }

                            span {
                                font-size: 16px;
                                font-weight: 400;
                                color: #DC1912;
                                margin-left: 15px;
                            }
                        }

                        .info_right {
                            display: flex;

                            padding: 0 20px;
                            align-items: center;
                            height: 28px;
                            background: linear-gradient(0deg, #FEDA62, #FFE06A);
                            border-radius: 14px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #EF2823;
                        }
                    }
                }

            }
        }
    }

    .pop_up {
        width: 100%;
        background-color: #00000094;
        position: fixed;
        top: 110%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width:370px) {
        .page .info {
            padding: 8px 8px
        }
    }
</style>