<template>
    <div class="page">
        <a-spin :spinning="isLoading" tip="加载中...">
            <div class="page_head">
                <img src="../../../static/images/sevenHead.png" alt="">
            </div>
            <div class="overall">
                <img class="overallImg" src="../../../static/images/sevenDraw.png" alt="" />
                <div class="newBox">
                    <div v-for="(i, index) in list" :key="index" class="card-box" @click="open(i, index)">
                        <div class="card" :class="{ active: i.activeIndex == index }">
                            <div class="f" :class="{ back: i.activeIndex != index }">
                                <span>{{ i.winner }}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <img class="overallBtn" @click="remdomBtn" src="../../../static/images/sevenBtn.png" alt="">
                <div class="info">
                    <div class="info_title">
                        <span style="margin-right: 5px;"></span>
                        中奖名单
                        <span style="margin-left: 5px;"></span>
                    </div>
                    <div class="info_list">
                        <div v-if="clientData.message&&clientData.message.length!=0">
                            <div class="list_box">
                                <span>{{ clientData.message.nickname }}</span>
                                <span>{{ clientData.message.prize_name }}</span>
                            </div>
                        </div>
                        <div class="recordBox" v-else>暂无中奖记录</div>
                    </div>
                </div>
            </div>
            <div class="record_title">
                <img src="../../../static/images/sevenRecond.png" alt="">
            </div>
            <div class="record_box">
                <div class="record_mar">
                    <div v-if="clientData.win_list&&clientData.win_list.lenth!=0">
                        <div v-for="(item,index) of clientData.win_list" :key="index">
                            <div class="record_list">
                                <div class="box_title">{{item.createdAt}}</div>
                                <div class="box_info">
                                    <div class="info_left">
                                        <img :src="item.prizeImage || item.receiveQr" alt="">
                                        <span>{{ item.prizeName }}</span>
                                    </div>
                                    <div v-if="item.receiveType == 3 ||item.receiveType == 4||item.receiveType == 6">
                                        <div class="info_right" v-if="item.receiveStatus == 0" @click="skip(item)">兑换
                                        </div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else>已兑换</div>
                                    </div>
                                    <div v-else>
                                        <div class="info_right" v-if="item.receiveStatus == 0"
                                            @click="receiveReward(item)">兑换</div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else @click="receiveReward(item)">已兑换</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-else description="暂无记录" />
                </div>
            </div>
            <div style="width: 100%;height: 40px;"></div>
            <div class="pop_up">
                <prize ref="prize" @childEvent="getClientData"/>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import { contactLotteryApi } from "@/api/lottery";
    import { publicMixin } from "@/mixin/index.js"
    export default {
        mixins: [publicMixin],
        data() {
            return {
                isLoading: false,
                openNumber: 300, // 剩余翻牌次数
                listNum: 9, // 卡片个数
                winner: null, // 当前抽中的奖品
                list: [], // 奖品列表
                clientData: {}, //  客户数据
                prizeSetNews: [],//  设置的奖品
                drawState: false,//是否抽奖
                // source: 'from_pc'
            }
        },
        created() {
            this.id = this.$route.query.id;
            this.getClientData()
            this.prizeSetNews = this.prizeData
            this.init()
        },
        methods: {
            // 翻牌抽奖
            // 初始化奖品列表，根据listNum生成奖品列表
            async init() {
                //奖品数量不够9个，重复
                let prizeLength = this.prizeSetNews.length;
                let newIndex = 0;
                let afyn=await new Promise((resolve,resject)=>{
                    this.prizeSetNews.forEach((item, index) => {
                    const itemData = {
                        activeIndex: null, // 翻开的下标
                        winner: null, // 获得的奖品
                        id: index
                    };
                    this.list.push(itemData);
                    resolve(this.list)
                   })
                })
                if(this.list.length<9){
                    this.addDarePrize()
                }
            },
            addDarePrize(){
                this.prizeSetNews.forEach((item, index) => {
                    const itemData = {
                        activeIndex: null, // 翻开的下标
                        winner: null, // 获得的奖品
                        id: this.list.length+1
                    };
                    if(this.list.length<9){
                        this.list.push(itemData)
                        console.log("list",this.list)
                    }
                    if(index+1===this.prizeSetNews.length&&this.list.length<9){
                        this.addDarePrize()
                    }

                })
            },
            //随机抽奖
            async remdomBtn() {
                if (this.drawState) return
                this.drawState = true
                let newlist = this.list.filter(item => item.activeIndex === null);//获取剩余牌数据
                let newIndex = newlist.map(item => item.id);//获取剩余下标
                let randomDraw = Math.floor(Math.random() * newIndex.length) + 1;//获取随机数
                let ramdomDataId = newIndex[randomDraw - 1]
                let ramdomData = this.list.filter(item => item.id === ramdomDataId)
                if (newIndex.length === 0) {
                    alert("已经翻完奖项了哟~");
                    return
                }
                await this.getWinner();
                this.drawState = false
                this.list.forEach((item, index) => {
                    if (ramdomDataId === item.id) {
                        item.activeIndex = index;
                        item.winner = this.winner;

                    }
                });

            },
            // 后端获取数据
            getWinner() {
                return new Promise((resolve) => {
                    //调取接口获取中奖数据
                    contactLotteryApi({
                        id: this.id,
                        union_id: this.weChatUserNews.unionid,
                        nickname: this.weChatUserNews.nickname,
                    }).then((res) => {
                        if (res.code == 200) {
                            let prizeInfo = {};
                            this.prizeSetNews.forEach((item, index) => {
                                if (item.name == res.data.prize_name) {
                                    prizeInfo = item;
                                }
                            });
                            this.winner = res.data.prize_name;
                            let prizeData = {
                                openId: this.openId,
                                receiveType: res.data.receive_type,
                                prizeName: res.data.prize_name,
                                receiveCode: res.data.receive_code,
                                receiveQr: res.data.receive_qr,
                                description: res.data.description,
                                reduce_amount:res.data.reduce_amount,
                                record_id: res.data.record_id,
                                nickname: this.weChatUserNews.nickname,
                                headimgurl: this.weChatUserNews.headimgurl,
                                money:res.data.money,
                            };
                            // if (prizeInfo.type == 2) {
                            prizeData.receiveQr = prizeInfo.image;
                            // }
                            this.$refs.prize.show(prizeData);
                            this.getClientData();
                            resolve();
                        }
                    }).finally(() => {
                        this.drawState = false
                    });
                });
            },
            open(row, i){
                this.debounce(this.openNext(row, i), 3000);
            },
            // 翻开卡片的方法
            async openNext(row, i) {
                // if (this.openNumber > 0) {
                // 判断用户没有翻开卡片
                if (!row.activeIndex) {
                    await this.getWinner();
                    this.list.forEach((item, index) => {
                        if (i === index) {
                            item.activeIndex = index;
                            item.winner = this.winner;

                        }
                    });
                    this.openNumber--;
                    // console.log(this.list);
                } else {
                    alert("你已经翻开过了哦");
                }
                // } else {
                //     alert("你的机会已经用完了哦");
                // }
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-empty{
        padding: 0px;
    }
    .page {
        width: 100%;
        height: 100%;
        background-image: url("../../../static/images/sevenBack.png");
        background-size: cover;

        .page_head {
            padding: 48px 20px 0 20px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .box {
        position: relative;
        /* margin: 50px auto; */
        margin: 0 auto;
        width: 800px;
        /* height: 440px; */
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000;

    }

    img {
        width: 100%;
        height: 100%;
    }

    .box div {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 9999;
        width: 100%;
        height: 100%;
        /* 主要内容 */
        /* background: rgba(0, 0, 0, 0.5); */
        /* 模糊大小就是靠的blur这个函数中的数值大小 */
        backdrop-filter: blur(10px);
    }

    .overall {
        perspective: 1000px;
        position: relative;

        .overallImg {
            width: 100%;
            height: 700px;
        }

        .overallBtn {
            position: absolute;
            width: 272px;
            height: 72px;
            top: 58%;
            left: 50%;
            z-index: 3;
            transform: translateX(-50%);
        }

        .info {
            position: absolute;
            top: 78%;
            width: 75%;
            padding: 8px 25px;
            margin: 0 auto;
            margin-top: 20px;
            border-top: #E29557 5px solid;
            background: #FEBE8D;
            border-radius: 5px;
            left: 50%;
            transform: translateX(-50%);

            .info_title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                font-weight: 500;
                color: #C90605;

                span {
                    width: 18px;
                    height: 2px;
                    background-color: #C90605;
                    /* border-top: 1px solid #C90605; */
                }
            }

            .info_list {
                margin-top: 2px;
                color: #C90605;

                .list_box {
                    display: flex;
                    justify-content: space-around;
                }
            }

            .recordBox {
                text-align: center;
                color: #C90605;
                font-size: 12px;
            }
        }
    }

    .record_title {
        display: flex;
        justify-content: center;
        margin-top: 33px;

        img {
            width: 225px;
            height: 27px;
        }
    }

    .record_box {
        background: #F24748;
        border-radius: 12px;
        margin: 15px;
        padding: 8px;
        margin-bottom: 30px;

        .record_mar {
            height: 233px;
            padding: 15px 22px;
            background: #FFFFFF;
            border-radius: 12px;
            overflow-y: scroll;

            .record_list {

                .box_title {
                    font-size: 12px;
                    font-weight: 400;
                    color: #DC1912;
                }

                .box_info {
                    margin-top: 5px;
                    padding: 10px 12px;
                    background: #FFE5D9;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .info_left {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        img {
                            display: flex;
                            flex-direction: row;
                            width: 45px;
                            height: 45px;
                        }

                        span {
                            font-size: 16px;
                            font-weight: 400;
                            color: #DC1912;
                            margin-left: 14px;
                        }
                    }

                    .info_right {
                        display: flex;

                        padding: 0 20px;
                        align-items: center;
                        height: 28px;
                        background: linear-gradient(0deg, #FEDA62, #FFE06A);
                        border-radius: 14px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #EF2823;
                    }
                }
            }

        }
    }

    .newBox {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        clear: both;
        /* margin: 25px 30px;  */
        width: 80vw;
        max-width: 400px;
        position: absolute;
        top: 6.5%;
        left: 50%;
        transform: translateX(-50%);

        .card-box {
            width: 28%;
            height: 6rem;
            position: relative;
            margin: 5px;
            cursor: pointer;
            user-select: none;
        }

        .card {
            width: 100%;
            height: 100%;
            perspective: 500px;
            transform-style: preserve-3d;
            transition: 0.5s;
            position: absolute;
            left: 0;
            top: 0;

            &.active {
                transform: rotateY(180deg);
            }


            /* 正面的样式 */
            .z {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: #FFF3CD;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 0 2px #cc9793;

                &:hover img {
                    transform: scale(1.5) translateX(-20%);
                }

                img {
                    transition: 0.3s;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                h3 {
                    position: relative;
                    z-index: 3;
                    color: white;
                    font-weight: 200;
                    font-size: 1rem;
                    display: inline-block;
                    border: 1px dashed white;
                    padding: 5px;
                }
            }

            /* 反面的样式 内容部分 */
            .f {
                outline: 1px dashed #FDDF41;
                outline-offset: -0.5rem;
                box-sizing: border-box;
                background: #FFF3CD;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                transform-style: preserve-3d;
                transform: rotateY(180deg) translateZ(1px);
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                border-radius: 15px;

                &.back {
                    background-image: url("../../../static/images/sevenpai.png");
                    background-size: 100% 100%;
                    transform: rotateY(0deg) translateZ(0px);
                    font-weight: bold;
                }
            }
        }
    }

    .pop_up {
        width: 100%;
        background-color: #00000094;
        position: fixed;
        top: 110%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width:370px) {
        .newBox {
            top: 5%;
        }
    }
</style>